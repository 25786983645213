$(document).ready(function() {
    var count_key = 2;
	var no_of_keyword = parseInt(window.no_of_keyword,10);

	$('.more-keyword > button').click(function(){

		if(no_of_keyword < keyword_limit) {

			if($(this).hasClass('removeButton')) {
				$(this).hide();
				$('#error-container').html('Your account can add upto '+keyword_limit+' keywords! Please delete some keyword to continue!');
				return false;
			}
            var large = '<div class="col-md-12 mb-2"><div class="input-group mb-3"><div class="input-group-prepend" style="height:42px;"><div class="input-group-text"><input type="checkbox" class="key-status" data-kw="" disabled></div></div><input type="text" class="form-control key" id="addKeyword_'+count_key+'" name="addKeyword[]" placeholder="'+text+'" /></div></div>';
			$('.form-row').append(large);
			$('#add-keyword-form .form-row').animate({scrollTop: $('.form-row').prop("scrollHeight")});
		}
		else {
			$('.more-keyword > button').hide();
            $('#error-container').html('Your account can add upto '+keyword_limit+' keywords! Please delete some keyword to continue!');
            return false;
		}
		count_key++;
	});

    $(document).on('input', '.key', function() {
        if($(this).val().trim() != '') {
            var checkbox = $(this).parent().find('.key-status');
            $(checkbox).attr('data-kw', encodeURI($(this).val().trim()));
            $(checkbox).prop('disabled', false);

            if(!$(checkbox).is(':checked'))
                $(checkbox).trigger('click');
        }
        else {
            var checkbox = $(this).parent().find('.key-status');
            $(checkbox).attr('data-kw', $(this).val().trim());

            if($(checkbox).is(':checked'))
                $(checkbox).trigger('click');

            $(checkbox).prop('disabled', true);
        }
    });

    $(document).on('change', '.key-status', function() {
        if($(this).attr('data-kw') !== undefined) {
            if($(this).is(':checked'))
                no_of_keyword += 1;
            else
                no_of_keyword -= 1;
        }

        if((no_of_keyword >= keyword_limit)) {
            $('.more-keyword > button').addClass('removeButton');
        }
        else if(no_of_keyword < keyword_limit) {
            $('.more-keyword > button').show();
            $('.more-keyword > button').removeClass('removeButton');
            $('#error-container').html('');
        }

        if(no_of_keyword > keyword_limit) {
            if($('.more-keyword > button').hasClass('removeButton')) {
                $('.more-keyword > button').hide();
                $(this).prop('checked', false);
                no_of_keyword -= 1;
                $('#error-container').html('Your account can add upto '+keyword_limit+' keywords! Please delete some keyword to continue!');
                return false;
            }
        }
        var key_per = (no_of_keyword / keyword_limit) * 100;

        $('.key_data').css('width', key_per+"%");
        $('.key_data').attr('aria-valuenow', key_per);
        $('.key_number').html(no_of_keyword);

        var progress = $('.key_data').attr('aria-valuenow');
        changeProgressColor(progress, '.key_data');
    });
});

$('#save_key').click( function() {
    if($('#projectName').val() == '') {
       $('#error-container1').html('please enter Project name');
        return false;
    }
    else
        $('#error-container1').html('');
    var arr = new Array();
    var key_status = new Array();
    var flag = 0;
    $('input[name^="addKeyword"]').each(function() {
        if($(this).val().trim() != '')   {
            if(!inArray($(this).val(), arr) && !inArray($(this).val(), keys)) {
                arr.push($(this).val().trim());
            }
            else  {
                $('#error-container').html('keyword already exist');
                flag = 1;
            }
        }
    });
    if(flag == 1)
        return false;

    $('.key-status').each(function() {
        if($(this).prop("checked") == true)
            var status = 1;
        else
            var status = 0;

        var val = decodeURI($(this).attr('data-kw'));
        if(val !== undefined && val.trim() != '') {
            key_status.push({kw:val, status:status});
        }
    });

    $('#inputKwds').val(JSON.stringify(arr));
    $('#keyStatus').val(JSON.stringify(key_status));

});
function inArray(needle, haystack) {
    var length = haystack.length;
    for(var i = 0; i < length; i++) {
        if(haystack[i] == needle) return true;
    }
    return false;
}

$(document).ready(function() {
    var count = 1;
    if( typeof(keys) !== 'undefined' ) {
        if(keys.length>0) {
            for(i=0;i<keys.length;i++) {
                var large = '<div class="col-md-12 mb-2"><div class="input-group mb-3"><div class="input-group-prepend" style="height:42px;"><div class="input-group-text"><input type="checkbox" class="key-status" data-kw="'+encodeURI(keys[i])+'"></div></div><input type="text" class="form-control" id="keyword_'+count+'" name="keyword[]" /></div></div>';
                $('.form-row').append(large);
                var id = $('#keyword_'+count);
                count++;
                id.attr('value', keys[i]);
                id.prop('disabled', true);
                id.css('opacity', 0.6);
                $('.ws-add-keyword .md-form label').css('font-size','12px');
                $('.ws-add-keyword .md-form label').css('top','-20px');
            }
            if(no_of_keyword < keyword_limit) {
                var large = '<div class="col-md-12 mb-2"><div class="input-group mb-3"><div class="input-group-prepend" style="height:42px;"><div class="input-group-text"><input type="checkbox" class="key-status" data-kw="" disabled></div></div><input type="text" class="form-control key" id="addKeyword_1" name="addKeyword[]" placeholder="'+text+'" /></div></div>';
            	$('.form-row').append(large);
        	}
        }
    }

    if ( keys_status.length > 0 ) {
      keys_status.forEach(function (item, index) {
          if(item.status !== undefined && item.status == 1) {
              $('.key-status[data-kw="'+encodeURI(item.kw)+'"]').prop('checked', true);
          }
          else {
              $('.key-status[data-kw="'+encodeURI(item.kw)+'"]').prop('checked', false);
          }
      });
    }
});
$(document).ready(function() {
  var bars = $('.light-house .progress-bar');
  for (i = 0; i < bars.length; i++) {
    var progress = $(bars[i]).attr('aria-valuenow');
    $(bars[i]).width(progress + '%');
    id = bars[i];
    changeProgressColor(progress, id);
  }
});
function changeProgressColor(progressValue, id) {
    if (progressValue <= 30) {
      $(id).css("background-color", "#33cc33");
    } else if (progressValue >= 31 && progressValue < 60) {
      $(id).css("background-color", "#FFCC00");
    } else if(progressValue >= 60 ) {
      $(id).css("background-color", "#ff0000");
    }
}
$(document).ready(function () {
    var btnVal = $("#previewData .btn_count_0").val();
    var myAudio = document.getElementById('preview-audio');
    if (myAudio !== undefined && myAudio!=null && myAudio.currentTime != undefined) {
      myAudio.currentTime = btnVal;
      $('.seek-btn').click(function(){
        btnVal = $(this).val();
        myAudio.currentTime = btnVal;
      });
    }

    $('#faqSubmit').click(function(){
        var message = $('#message').val().trim();
        var email = $('#email').val().trim();
        var check_error = false;

        if (message == "") {
            $('#invalid-msg-feedback').html("Please check your message");
            check_error = true;
        }
        else {
            $('#invalid-msg-feedback').html('');
        }

        if (email == "") {
            $('#invalid-email-feedback').html("Please check your e-mail");
            check_error = true;
        }
        else {
            var filter_email = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,63}|[0-9]{1,3})(\]?)$/;
            if(!filter_email.test(email)) {
                $('#invalid-email-feedback').html('Please enter the valid email address');
                check_error = true;
            }
            else {
                $('#invalid-email-feedback').html('');
            }
        }
        if(check_error) {
            return false;
        }
        else {
            $.ajax({
                type : 'post',
                url : '/faq-submit',
                data: {'_token':$('#_token').val(), 'message':message, 'email':email, 'g-recaptcha-response':$('#g-recaptcha-response').val()},
                success:function(data){
                if(data == 'error') {
                    $('.main-container.article-area').prepend('<div class="container" style="margin-top: -75px;margin-bottom: 20px;"><div class="row"><div class="col-12"><div class="alert alert-danger" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button>Error verifying reCAPTCHA, please try again</div></div></div></div>');
                }
                else if(data=='true'){
                    $('.main-container.article-area').prepend('<div class="container" style="margin-top: -75px;margin-bottom: 20px;"><div class="row"><div class="col-12"><div class="alert alert-success" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button>FAQ Submitted</div></div></div></div>');
                }
                $('#email').val('');
                $('#message').val('');
                $('.msg_container').show();
                $("html").scrollTop(0);
                }
            });
        }
    });

    if($('#myTab .active').parent().next('li').length) {
        $('.next-artical').show();
        $('.artical-alert').text($('.nav-pills .active').parent().next('li').find('a').text());
    }
    else {
        $('.next-artical').hide();
    }

  $('.faq-next').click(function() {
    var nxtTab = $('.nav-pills .active').parent().next('li').next('li');
    var nxtTabText = $('.nav-pills .active').parent().next('li').next('li').find('a').text();
    var sublink = $('.nav-pills .dropdown-menu .active').next()
    var sublinkText = $('.nav-pills .dropdown-menu .active').next().text();
    if (nxtTab.length) {
      if (sublink.length) {
        $('.nav-pills .dropdown-menu .active').next().trigger('click');
        $('.artical-alert').text(sublinkText);
      } else {
        window.location.replace($('.nav-pills .active').parent().next('li').find('a').attr('href'));
      }
    }
    else {
        window.location.replace($('.nav-pills .active').parent().next('li').find('a').attr('href'));
        $('.next-artical').hide();
    }
  });

    $(window).scroll(function(){
        let height = $( document ).height()-800;

        if($(this).scrollTop() > height){
            $('.article-area .faq-accord .mob-nav').css({top:'0px', position:'relative'});
        }
        else {
            $('.article-area .faq-accord .mob-nav').css({top:'100px', position:'sticky'});
        }
    });

    $('.cross').click(function() {
        $('.price-alert-pop').hide(300);
    });

    $("#card-name").keydown(function(event){
        var inputValue = event.which;
        // allow letters and whitespaces only.
        if(!(inputValue >= 65 && inputValue <= 120) && (inputValue != 32 && inputValue != 0 && inputValue != 8)) {
            event.preventDefault();
        }
    });
})
